<template>
  <div>
    <b-card>
      <h2>{{ helpContent.title }}</h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="helpContent.detailText" />
      <template v-if="(helpContent.faq || []).length > 0">
        <h3>Часто задаваемые вопросы</h3>
        <i-collapse-item-card
          v-for="item in helpContent.faq"
          :key="item.id"
          :item="item"
        >
          <template slot="title">
            <div class="h6">
              {{ item.title }}
            </div>
          </template>
          <template>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="item.text" />
          </template>
        </i-collapse-item-card>
      </template>

    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import router from '@/router'
import {
  BCard,
} from 'bootstrap-vue'
import contentHelpService from '@/application/contentHelpService'
import ICollapseItemCard from '@/components/i-collapse-link-card/ICollapseItemCard.vue'

export default {
  components: {
    BCard,

    ICollapseItemCard,
  },
  setup() {
    const elementId = ref(router.currentRoute.params.id)
    const helpContent = ref({})
    const { getElement } = contentHelpService()

    getElement(elementId.value).then(res => {
      if (!res) {
        router.replace({ name: 'error-404' })
        return
      }
      helpContent.value = res
    })

    return { helpContent }
  },
}
</script>
