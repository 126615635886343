<template>
  <a
    :class="{'collapsed': !visible}"
    class="collapsed-list list-group-item mb-1"
    style="border-radius: 0.428rem;"
    @click="visible = !visible"
  >
    <div class="d-flex w-100 justify-content-between pr-2">
      <div>
        <slot name="before-title" />
      </div>
      <slot name="badge" />
    </div>
    <div class="d-flex w-100 justify-content-between">
      <slot name="title" />

      <feather-icon
        icon="ChevronUpIcon"
        size="16"
      />
    </div>
    <small>
      <slot name="after-title" />
    </small>
    <b-collapse
      :id="String(item.id)"
      v-model="visible"
      :accordion="`accordion-${ String(item.id)}`"
      role="tabpanel"
      :appear="false"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </a>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCollapse, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    BCardBody,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  setup() {
    const visible = ref(false)

    return { visible }
  },
}
</script>

<style>
.collapsed-list.list-group-item{
  border-top-width: 1px;
}

.collapsed-list.collapsed svg {
    transform: rotate(-180deg);
}
.collapsed-list svg {
    transition: all 0.25s ease-out;
}
</style>
